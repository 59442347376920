import { createAction, props } from '@ngrx/store';
import { EsmRevenueHeadDetailReducerInitialState } from '../reducers/admin-esm-revenue-head-detail.reducer';

const prefix = '[Admin-esm-revenue-head-detail]';

export const setStateAction = createAction(
  `${prefix} set state`,
  props<Partial<EsmRevenueHeadDetailReducerInitialState>>()
);

export const setQueryAction = createAction(
  `${prefix} set query`,
  props<
    Partial<{
      minDate: string;
      maxDate: string;
    }>
  >()
);

export const resetQueryAction = createAction(
  `${prefix} reset query`,
  props<any>()
);
