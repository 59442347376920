import { createAction, props } from '@ngrx/store';
import { ExpertSystemModuleReducerInitialState } from '../reducers/admin-expert-system-module.reducer';
import { QueryClient } from '@tanstack/angular-query-experimental';

const prefix = '[Admin-expert-system-module]';

export const setStateAction = createAction(
  `${prefix} set state`,
  props<Partial<ExpertSystemModuleReducerInitialState>>()
);

export const setQueryAction = createAction(
  `${prefix} set query`,
  props<
    Partial<{
      minDate: string;
      maxDate: string;
      esId: string;
      // successAction?: (queryClient: QueryClient) => any;
    }>
  >()
);

export const resetQueryAction = createAction(
  `${prefix} reset query`,
  props<any>()
);
