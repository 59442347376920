<div [ngClass]="{ 'w-full h-dvh overflow-auto flex flex-col': true }">
  <div [ngClass]="{ 'text-center pt-9 pb-8': true }">
    <button
      [ngClass]="{ 'text-tw-primary text-base capitalize': true }"
      (click)="backToDashboard()"
    >
      Back to Dashboard
    </button>
  </div>

  <app-receipt
    [receiptData]="receiptData()"
    [ref]="ref"
    [isLoading]="isLoading()"
    [isInvoice]="statusSignal() === 'Unpaid' ? true : false"
  ></app-receipt>
</div>
